<template>
    <div class="member-page">

        <div class="top-info">
            <el-row :gutter="20">
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <h3>今日生日</h3>
                        <p>{{table.birthday.today_count}}位</p>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <h3>近7天生日</h3>
                        <p>{{table.birthday.seven_days_count}}位</p>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <h3>总会员</h3>
                        <p>{{pagination.total}}位</p>
                    </div>
                </el-col>
            </el-row>
        </div>
        
        <div class="box-container">

            <!-- 筛选条件 start -->
            <div class="global-search-box">
                <el-select v-model="member_config.grade_value" :placeholder="member_config.placeholder" @change="gradeChange">
                    <el-option
                        v-for="item in member_config.grade_list"
                        :key="item.grade_id"
                        :label="item.grade_name"
                        :value="item.grade_id">
                    </el-option>
                </el-select>
                <div class="global-ml-10">
                    <el-input class="search-input" v-model="search_config.search_value" :placeholder="search_config.placeholder" @keyup.enter="searchMember">
                        <template #append>
                            <el-button icon="el-icon-search" @click="searchMember"></el-button>
                        </template>
                    </el-input>
                </div>
                <el-button class="add-member" @click="openMemberPopup" v-allow="['add']">
                    <i class="el-icon-plus"></i>
                    <span>添加会员</span>
                </el-button>
            </div>
            <!-- 筛选条件 end -->

            <!-- 会员列表 start -->
            <div class="table-list">
                <table-component :table="table">
                    <template #default="scope">
                        <div v-if="scope.item.prop == 'alcohol_id'">
                            <div class="link" @click="openWinePopup(scope.scope.row)" v-allow="['viewWine']">查看存酒</div>
                        </div>
                        <div v-else-if="scope.item.prop == 'control'">
                            <el-button size="mini" @click="editMemberPopup(scope.scope.row)" v-allow="['edit']">编辑</el-button>
                            <el-button size="mini" @click="openMemberPurchaseoPopup(scope.scope.row)" v-allow="['purchase']">充值</el-button>
                            <el-button size="mini" @click="openRunningWaterPopup(scope.scope.row)" v-allow="['runningWater']">流水</el-button>
                        </div>
                        <div v-else-if="scope.item.prop == 'created_at'">
                                <span>{{formatFullTime(scope.scope.row[scope.item.prop])}}</span>
                            </div>
                        <div v-else>{{scope.scope.row[scope.item.prop]}}</div>
                    </template>
                </table-component>
            </div>
            <!-- 会员列表 end -->

            <!-- 分页 start -->
            <div class="pagination-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pagination.page"
                    :page-sizes="pagination.page_sizes"
                    :page-size="pagination.page_size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagination.total">
                </el-pagination>
            </div>
            <!-- 分页 end -->
            
        </div>

        <!-- 添加/编辑会员会员 start -->
        <div>
            <el-dialog
                :title="member_popup_config.title"
                v-model="member_popup_config.is_show"
                width="800px"
                :show-close="false"
                :close-on-click-modal="false"
                :close-on-press-escape="false">
                <div class="add-member-dialog">
                    <div class="dialog-title">会员信息</div>
                    <el-form :inline="true" :model="member_popup_config.form" class="demo-form-inline" label-width="100px">
                        <el-form-item class="change-width-input" label="会员名称">
                            <el-input v-model="member_popup_config.form.name" placeholder="请输入会员名称"></el-input>
                        </el-form-item>
                        <el-form-item class="change-width-input" label="手机号码">
                            <el-input v-model="member_popup_config.form.phone" placeholder="请输入手机号码"></el-input>
                        </el-form-item>
                        <el-form-item label="称调">
                            <el-select v-model="member_popup_config.form.sex">
                                <el-option label="先生" :value="1"></el-option>
                                <el-option label="女士" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="会员级别">
                            <el-select v-model="member_popup_config.form.grade_id">
                                <el-option
                                    v-for="item in member_popup_config.grade_list"
                                    :key="item.grade_id"
                                    :label="item.grade_name"
                                    :value="item.grade_id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="生日">
                            <el-date-picker
                                v-model="member_popup_config.form.birthday"
                                type="date"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="订台人">
                            <el-autocomplete value-key="name" class="inline-input" v-model="member_popup_config.form.market_name" :fetch-suggestions="querySearch" placeholder="请输入订台人" :trigger-on-focus="false" @select="handleSelect"></el-autocomplete>
                        </el-form-item>
                    </el-form>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="cancelMemberAdd">取 消</el-button>
                        <el-button type="primary" @click="memberUserAdd">{{member_popup_config.form.id?'修 改':'确 认'}}</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
        <!-- 添加/编辑会员会员 end -->

        <!-- 查看存酒 start -->
        <el-dialog
            :title="wine_popup_config.title"
            v-model="wine_popup_config.is_show"
            width="1000px"
            :before-close="handleClose"
            :showClose='false'>
            <div class="wine-popup-dialog">
                <div class="member-info">
                    <div class="dialog-info-item">
                        <span>会员姓名：{{wine_popup_config.from.name}}</span>
                        <span>手机号：{{wine_popup_config.from.phone}}</span>
                    </div>
                </div>
                <div>
                    <table-component :table="wine_popup_config.table">
                        <template #default="scope">
                            <div v-if="scope.item.prop == 'allowance'">
                                <div>{{scope.scope.row.num}}{{scope.scope.row.allowance_unit}}</div>
                                <el-progress :percentage="scope.scope.row[scope.item.prop]"></el-progress>
                            </div>
                            <div v-else>{{scope.scope.row[scope.item.prop]}}</div>
                        </template>
                    </table-component>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="wine_popup_config.is_show = false">关闭</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 查看存酒 end -->

        <!-- 会员充值 start -->
        <el-dialog
            :title="member_purchase_popup_config.title"
            v-model="member_purchase_popup_config.is_show"
            width="600px"
            :before-close="handleClose"
            :showClose='false'>
            <div class="member-purchase-popup-dialog">
                <div class="dialog-title">会员信息</div>
                <div class="member-info">
                    <div class="dialog-info-item">
                        <span>会员姓名：{{member_purchase_popup_config.from.name}}</span>
                        <span>手机号：{{member_purchase_popup_config.from.phone}}</span>
                    </div>
                    <div class="dialog-info-item">
                        <span>销售：{{member_purchase_popup_config.from.market_name}}</span>
                    </div>
                </div>
                <div>
                    <div class="dialog-title">充值金额</div>
                    <el-radio-group v-model="member_purchase_popup_config.money_id" size="small" @change="purchaseRadioChange">
                        <el-radio class="global-mb-10" :label="item.recharge_id" border v-for="(item,index) of member_purchase_popup_config.money_list" :key="index">{{item.recharge_name}}</el-radio>
                        <el-input type="number" @blur="pruchaseBlur" @input="pruchaseInput" class="global-mb-10 purchase-input" :class="{'active':member_purchase_popup_config.custom_amount}" v-model="member_purchase_popup_config.custom_amount" placeholder="输入充值金额"></el-input>
                    </el-radio-group>
                </div>
                <div>
                    <div class="dialog-title">支付方式</div>
                    <el-radio-group v-model="member_purchase_popup_config.pay_id" size="small" @change="payIdChange">
                        <el-radio class="global-mb-10" :label="item.id" border v-for="(item,index) of member_purchase_popup_config.pay_list" :key="index">{{item.name}}</el-radio>
                    </el-radio-group>
                </div>
                <div class="global-mt-10">
                    <div v-if="member_purchase_popup_config.payment_method == 2 || member_purchase_popup_config.payment_method == 3" class="serial-code">
                        <el-input type="text" placeholder="请输入条形码" v-model="member_purchase_popup_config.paycode" @keyup.enter="rechargeHandle"></el-input>
                    </div>
                    <div v-else-if="member_purchase_popup_config.payment_method == 1" class="serial-select">
                        <el-select v-model="member_purchase_popup_config.equipment_id" placeholder="请选择" @change="deviceChange" >
                            <el-option v-for="item of member_purchase_popup_config.device_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeRechargeHandle">关闭</el-button>
                    <el-button @click="rechargeHandle" type="primary">确认充值</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 会员充值 end -->

        <!-- 流水 start -->
        <el-dialog
            :title="running_water_popup_config.title"
            v-model="running_water_popup_config.is_show"
            width="1000px"
            :before-close="handleClose"
            :showClose='false'>
            <div class="running-water-popup-dialog">
                <div class="dialog-title">会员信息</div>
                <div class="member-info">
                    <div class="dialog-info-item">
                        <span>会员姓名：{{running_water_popup_config.from.name}}</span>
                        <span>手机号：{{running_water_popup_config.from.phone}}</span>
                    </div>
                </div>
                <div>
                    <table-component :table="running_water_popup_config.table">
                        <template #default="scope">
                            <div v-if="scope.item.prop == 'flow_type'">
                                <div v-if="scope.scope.row[scope.item.prop] == 1">消费</div>
                                <div v-else-if="scope.scope.row[scope.item.prop] == 2">充值</div>
                                <div v-else-if="scope.scope.row[scope.item.prop] == 3">赠送</div>
                                <div v-else-if="scope.scope.row[scope.item.prop] == 4">清账</div>
                            </div>
                            <div v-else>{{scope.scope.row[scope.item.prop]}}</div>
                        </template>
                    </table-component>
                    <!-- 分页 start -->
                    <div class="pagination-box">
                        <el-pagination
                            @size-change="runningWaterHandleSizeChange"
                            @current-change="runningWaterHandleCurrentChange"
                            :current-page="running_water_popup_config.pagination.page"
                            :page-sizes="running_water_popup_config.pagination.page_sizes"
                            :page-size="running_water_popup_config.pagination.page_size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="running_water_popup_config.pagination.total">
                        </el-pagination>
                    </div>
                    <!-- 分页 end -->
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="clenFun">关闭</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 流水 end -->

    </div> 
</template>
<script>
import { memberApi,pubDeskApi } from '@/api'
import { formatFullDate,formatFullTime,customFormatFullTime } from '@/assets/js/utils'
import { toDecimal } from "@/assets/js/utils";
import tableComponent from '@/components/table'
export default {
    name:'member-page',
    components:{
        tableComponent
    },
    data(){
        return{
            pagination:{
                page:1,
                page_size:10,
                total:0,
                page_sizes:[10,20,30]
            },
            running_water_popup_config:{
                title:'会员流水',
                is_show:false,
                member_id:'',
                from:{
                    name:'',
                    phone:''
                },
                filter:{
                    time:'',
                    type_id:'',
                    type_list:[
                        {
                            value:1,
                            label:'充值'
                        },
                        {
                            value:2,
                            label:'赠送'
                        },
                        {
                            value:3,
                            label:'消费'
                        }
                    ]
                },
                table:{
                    column_key:[
                        {
                            prop:'order_num',
                            label:'流水单号'
                        },
                        {
                            prop:'created_at',
                            label:'时间'
                        },
                        {
                            prop:'money',
                            label:'金额'
                        },
                        {
                            prop:'flow_type',
                            label:'类型'
                        },
                        {
                            prop:'remarks',
                            label:'说明'
                        },
                        {
                            prop:'none',
                            label:'备注'
                        }
                    ],
                    list:[]
                },
                pagination:{
                    page:1,
                    page_size:10,
                    total:0,
                    page_sizes:[10,20,30]
                },
            },
            member_purchase_popup_config:{
                title:'查看会员充值',
                is_show:false,
                money_id:'',
                pay_id:'',
                paycode:'',//条形码（支付宝或微信需要）
                equipment_id:'', //设备id
                equipment_num:'',//设备号
                custom_amount:'',// 自定义金额
                recharge_amount:'', // 选中的金额
                device_list:[], //设备列表
                from:{
                    id:'',
                    name:'',
                    phone:'',
                    market_name:''
                },
                money_list:[],
                pay_list:[],
                payment_method:''
            },
            wine_popup_config:{
                title:'查看会员存酒',
                is_show:false,
                table:{
                    column_key:[
                        {
                            prop:'wine_name',
                            label:'商品名称'
                        },
                        {
                            prop:'allowance',
                            label:'剩余'
                        },
                        {
                            prop:'created_at',
                            label:'存入时间'
                        },
                        {
                            prop:'expiration_time',
                            label:'到期时间'
                        },
                        {
                            prop:'remarks',
                            label:'备注'
                        }
                    ],
                    list:[]
                },
                from:{
                    name:'',
                    phone:''
                }
            },
            member_popup_config:{
                title:'',
                is_show:false,
                grade_list:[],
                form:{
                    name:'',
                    phone:'',
                    sex:'',
                    grade_id:0,
                    birthday:'',
                    market_id:0,
                    market_name:0,
                    source:3
                }
            },
            table:{
                column_key:[
                    {
                        prop:'name',
                        label:'姓名'
                    },
                    {
                        prop:'phone',
                        label:'手机号'
                    },
                    {
                        prop:'grade_name',
                        label:'会员级别'
                    },
                    {
                        prop:'balance',
                        label:'账户余额'
                    },
                    {
                        prop:'gift_balance',
                        label:'赠送余额'
                    },
                    {
                        prop:'integral',
                        label:'积分余额'
                    },
                    {
                        prop:'created_at',
                        label:'注册时间'
                    },
                    {
                        prop:'alcohol_id',
                        label:'存酒信息'
                    },
                    // {
                    //     prop:'consume_id',
                    //     label:'优惠券'
                    // },
                    {
                        prop:'control',
                        label:'操作',
                        width:300,
                    }
                ],
                birthday:{},
                list:[]
            },
            search_config:{
                search_value:'',
                placeholder:'会员姓名、手机号、卡号'
            },
            member_config:{
                placeholder:'会员级别',
                grade_value:0,
                grade_list:[]
            },
        }
    },
    watch:{
        'member_purchase_popup_config.is_show':{
            handler(newVal){
                if(newVal){
                    this.mealGetPayWayList()
                }
            },
            deep:true
        }
    },
    created(){
        this.memberGradeData()
        this.memberUserList()
    },
    mounted(){
        
    },
    methods:{
        payIdChange(){
            // 切换支付方式、清空选中设备或条形码
            this.member_purchase_popup_config.equipment_id = ''
            this.member_purchase_popup_config.equipment_num = ''
            this.member_purchase_popup_config.paycode = ''
            
            let pay_list = this.member_purchase_popup_config.pay_list
            for(let i in pay_list){
                if(pay_list[i].id == this.member_purchase_popup_config.pay_id){
                    this.member_purchase_popup_config.payment_method = pay_list[i].type
                    break;                    
                }
            }

        },
        /**
         * 支付方式列表
         */
        mealGetPayWayList() {
            pubDeskApi.mealGetPayWayList({
                module_type:2, //1正常 2充值 3挂账
            }).then((res) => {
                this.member_purchase_popup_config.pay_list = res.data.list;
            });
        },
        deviceChange() {
            let device_list = this.member_purchase_popup_config.device_list;
            for (let i in device_list) {
                if (device_list[i].id == this.member_purchase_popup_config.equipment_id) {
                    this.member_purchase_popup_config.equipment_num = device_list[i].equipment_num;
                    break;
                }
            }
        },
        /**
         * 获取设备列表
         */
        getDevicesList(){
            pubDeskApi.orderEquipment().then((res) => {
                this.member_purchase_popup_config.device_list = res.data;
            });
        },
        /**
         * 编辑会员信息
         */
        editMemberPopup(row){
            memberApi.memberUserListone({
                id:row.id
            }).then(res=>{
                res.data['id'] = row.id
                this.member_popup_config.form = res.data
                this.openMemberPopup(1)
            })
        },
        searchMember(){
            this.memberUserList()
        },
        gradeChange(){
            this.memberUserList()
        },
        formatFullTime(){
            return formatFullTime(...arguments)
        },
        /**
         * 输入- 模糊查询订台人
         */
        async querySearch(queryString, cb){
            if(!queryString.trim()){
                return;
            }
            let results = []
            await this.memberVagueUserInfo(queryString).then(list=>{
                console.log('list',list)
                for(let i in list){
                    list[i]['name'] = list[i].realname + ' - ' + list[i].department_name
                }
                results = list
            })
            cb(results)
        },
        /**
         * 模糊查询员工部门
         */
        memberVagueUserInfo(value){
            return new Promise((resolve,reject)=>{
                memberApi.memberVagueUserInfo({
                    realname:value
                },{
                    close_loading:true
                }).then(res=>{
                    resolve(res.data)
                })
            })
        },
        /**
         * 选择订台人
         */
        handleSelect(item){
            console.log('item',item)
            this.member_popup_config.form.market_name = item.name
            this.member_popup_config.form.market_id = item.id
        },
        /**
         * 会员等级列表(全部)
         */
        memberGradeData(){
            memberApi.memberGradeData().then(res=>{
                
                this.member_popup_config.grade_list = res.data
                this.member_config.grade_list = [{
                    count: 0,
                    grade_id: 0,
                    grade_name: "全部" 
                }].concat(res.data)
            })
        },
        /**
         * 关闭会员添加
         */
        cancelMemberAdd(){
            this.member_popup_config.is_show = false
            this.resetFormMemberAdd()
        },
        resetFormMemberAdd(){
            this.member_popup_config.form.id = ''
            this.member_popup_config.form.name = ''
            this.member_popup_config.form.phone = ''
            this.member_popup_config.form.sex = 1
            this.member_popup_config.form.grade_id = ''
            this.member_popup_config.form.birthday = ''
            this.member_popup_config.form.market_id = ''
            this.member_popup_config.form.market_name = ''

            delete this.member_popup_config.form.grade_name
            delete this.member_popup_config.form.market_name
            delete this.member_popup_config.form.department_name
        },
        memberUserAdd(){
            let form = JSON.parse(JSON.stringify(this.member_popup_config.form))
            if(!form.name.trim()){
                this.$message.warning('请输入会员名称')
                return;
            }else if(!form.phone.trim()){
                this.$message.warning('请输入手机号码')
                return;
            }else if(!form.sex){
                this.$message.warning('请选择称调')
                return;
            }

            if(form.birthday){
                form.birthday = formatFullDate(form.birthday)
            }

            if(!form.grade_id){
                form.grade_id = 0
            }
            if(!form.market_id){
                form.market_id = 0
            }

            // 生成会员快捷码
            if(form.name && form.name.trim()){
                form['member_initials'] = pinyin.getCamelChars(form.name)
            }

            if(form.id){
                delete form.grade_name
                delete form.market_name
                delete form.department_name
                memberApi.memberUserEdit(form).then(res=>{
                    this.$message.success('修改成功')
                    this.cancelMemberAdd()
                    this.resetSearchCondition()
                    this.memberUserList()
                })
            }else{
                memberApi.memberUserAdd(form).then(res=>{
                    this.$message.success('添加成功')
                    this.cancelMemberAdd()
                    this.resetSearchCondition()
                    this.memberUserList()
                })
            }
        },
        resetSearchCondition(){
            this.pagination.page = 1
            this.member_config.grade_value = 0
            this.search_config.search_value = ''
        },
        memberUserList(){
            memberApi.memberUserList({
                grade_id:this.member_config.grade_value || 0,
                member:this.search_config.search_value,
                page:this.pagination.page,
                pageSize:this.pagination.page_size,
            }).then(res=>{
                this.pagination.total = res.data.total
                this.table.birthday['today_count'] = res.data.today_count
                this.table.birthday['seven_days_count'] = res.data.seven_days_count
                this.table.list = res.data.list
            })
        },
        //获取充值金额列表
        memberRechargeInfo(){
            memberApi.memberRechargeInfo().then(res=>{
                this.member_purchase_popup_config.money_list = res.data
            })
        },
        handleSizeChange(page_size){
            this.pagination.page_size = page_size
            this.memberUserList()
        },
        handleCurrentChange(page){
            this.pagination.page = page
            this.memberUserList()
        },
        runningWaterHandleSizeChange(page_size){
            this.running_water_popup_config.pagination.page_size = page_size
            this.memberFlowRecordList()
        },
        runningWaterHandleCurrentChange(page){
            this.running_water_popup_config.pagination.page = page
            this.memberFlowRecordList()
        },
        runingWaterQuery(){
            // this.memberFlowRecordList()
        },
        //会员流水记录
        memberFlowRecordList(){
            let start_time = this.running_water_popup_config.filter.time[0]
            let end_time = this.running_water_popup_config.filter.time[1]
            memberApi.memberFlowRecordList({
                member_id:this.running_water_popup_config.member_id,
                start_time,
                end_time,
                type:this.running_water_popup_config.filter.type_id,
                page:this.running_water_popup_config.pagination.page,
                pageSize:this.running_water_popup_config.pagination.page_size
            }).then(res=>{
                for(let i in res.data.list){
                    res.data.list[i].created_at = customFormatFullTime(res.data.list[i].created_at,'YYYY-MM-DD h:m:s')
                }
                this.running_water_popup_config.table.list = res.data.list
                this.running_water_popup_config.pagination.total = res.data.total
            })
        },
        openRunningWaterPopup(row){
            this.running_water_popup_config.member_id = row.id
            this.running_water_popup_config.from.name = row.name
            this.running_water_popup_config.from.phone = row.phone
            this.running_water_popup_config.is_show = true
            this.memberFlowRecordList()
        },
        openMemberPurchaseoPopup(row){
            this.member_purchase_popup_config.from.id = row.id
            this.member_purchase_popup_config.from.name = row.name
            this.member_purchase_popup_config.from.phone = row.phone
            this.member_purchase_popup_config.from.market_name = row.market_name
            this.member_purchase_popup_config.is_show = true
            this.$nextTick(()=>{
                this.memberRechargeInfo()
                this.getDevicesList()
            })
        },
        openWinePopup(row){
            this.wine_popup_config.from.name = row.name
            this.wine_popup_config.from.phone = row.phone
            this.wine_popup_config.is_show = true
            //存酒信息
            memberApi.memberUserWineInfo({
                id:row.id
            }).then(res=>{
                for(let i in res.data){
                    res.data[i].created_at = customFormatFullTime(res.data[i].created_at,'YYYY-MM-DD h:m:s')
                }
                this.wine_popup_config.table.list = res.data
            })
        },
        clenFun(){
            this.running_water_popup_config.filter.type_id = ''
            this.running_water_popup_config.filter.time = ''
            this.running_water_popup_config.pagination.page = 1
            this.running_water_popup_config.is_show = false
        },
        rechargeHandle(){
            // 自定义金额为空 || 选中的金额为空
            if(!this.member_purchase_popup_config.recharge_amount && !this.member_purchase_popup_config.custom_amount){
                this.$message.warning('请选择或输入金额')
                return
            }else if(!this.member_purchase_popup_config.pay_id){
                this.$message.warning('请选择支付方式')
                return
            }

            let recharge_amount = ''
            if(this.member_purchase_popup_config.recharge_amount){
                recharge_amount = this.member_purchase_popup_config.recharge_amount
            }else if(this.member_purchase_popup_config.custom_amount){
                recharge_amount = this.member_purchase_popup_config.custom_amount
            }


            // post机支付 && 没有设备号
            if(this.member_purchase_popup_config.pay_id == 1 && !this.member_purchase_popup_config.equipment_num){
                this.$message.warning('请选择设备')
                return;
            }

            // 支付宝或微信 && 条形码
            if( (this.member_purchase_popup_config.pay_id == 2 || this.member_purchase_popup_config.pay_id == 3) && !this.member_purchase_popup_config.paycode){
                this.$message.warning('请输入条形码')
                return;
            }

            //确认充值
            memberApi.memberConfirmRecharge({
                member_id:this.member_purchase_popup_config.from.id,
                recharge_id:this.member_purchase_popup_config.money_id || 0,
                pay_id:this.member_purchase_popup_config.pay_id,
                recharge_amount, // 重置金额
                equipment_num:this.member_purchase_popup_config.equipment_num, //设备号
                paycode:this.member_purchase_popup_config.paycode, //条形码
            }).then(res=>{
                if(this.member_purchase_popup_config.pay_id == 4){
                    this.$message.warning(`请在"收银 - 充值列表" 进行审核`)
                    this.refreshMemberPurchasePopup()
                }else if(res.data && res.data.stored_value_log_id){
                    this.$message.warning(res.data.msg)
                    this.$confirm(`请在手机操作，是否确认充值成功?`, '提示', {
                        confirmButtonText: '充值成功',
                        cancelButtonText: '充值失败',
                        type: 'warning',
                    }).then(() => {
                        this.orderRefreshPayStatus(res.data.stored_value_log_id)
                    }).catch(()=>{
                        this.orderRefreshPayStatus(res.data.stored_value_log_id)
                    });
                }else{
                    this.$message.success('操作成功')
                    this.refreshMemberPurchasePopup()
                }
            })
        },
        /**
         * 刷新充值状态
         */
        orderRefreshPayStatus(id){
            // 二次确认回调
            pubDeskApi.orderRefreshPayStatus({
                stored_value_log_id:id,
            }).then((data) => {
                // 这里后续需要判断支付状态 失败不关闭弹框
                this.$message.success('已操作')
                this.refreshMemberPurchasePopup()
            })
        },
        /**
         * 关闭弹窗并刷新会员列表
         */
        refreshMemberPurchasePopup(){
            this.closeRechargeHandle()
            this.memberUserList()
        },
        closeRechargeHandle(){
            this.member_purchase_popup_config.money_id = '',
            this.member_purchase_popup_config.pay_id = ''
            this.member_purchase_popup_config.payment_method = ''
            this.member_purchase_popup_config.recharge_amount = '',
            this.member_purchase_popup_config.custom_amount = '',
            this.member_purchase_popup_config.equipment_num = '' //设备号
            this.member_purchase_popup_config.paycode = '' //条形码
            this.member_purchase_popup_config.is_show = false
        },
        openMemberPopup(type){
            if(type == 1){
                this.member_popup_config.title = '编辑会员'
            }else{
                this.member_popup_config.title = '添加会员'
            }
            this.member_popup_config.is_show = true
        },
        pruchaseInput(){
            this.member_purchase_popup_config.money_id = ''
            this.member_purchase_popup_config.recharge_amount = ''
        },
        pruchaseBlur(){
            if(this.member_purchase_popup_config.custom_amount){
                this.member_purchase_popup_config.custom_amount = toDecimal(this.member_purchase_popup_config.custom_amount)
            }
        },
        purchaseRadioChange(){
            for(let i in this.member_purchase_popup_config.money_list){
                if(this.member_purchase_popup_config.money_id == this.member_purchase_popup_config.money_list[i].recharge_id){
                    this.member_purchase_popup_config.recharge_amount = this.member_purchase_popup_config.money_list[i].recharge_amount
                    break;
                }
            }
            this.member_purchase_popup_config.custom_amount = ''
        },
        handleClose(){
            
        }
    }
}
</script>
<style lang="scss" scoped>
.member-page{
    padding-bottom:40px;
    .box-container{
        padding: 20px;
        margin: 20px;
        background-color: white;
    }
    .add-member {
        margin-left: 10px;
        // color: rgb(255, 202, 66);
    }
    .change-width-input{
        & /deep/ .el-input__inner{
            width:203px!important;
        }
    }
    .filter-box{
        margin-bottom:30px;
        .select{
            margin-left:10px;
        }
        .button{
            margin-left:10px;
        }
    }
    .member-purchase-popup-dialog{
        & /deep/ .el-radio{
            margin-left:0;
            // margin-bottom:10px;
        }
        .purchase-input{
            width: 130px;
            overflow: hidden;
            display: inline-flex;
            &.active{
                & /deep/ .el-input__inner{
                    color:#409EFF;
                    border:1px solid #409EFF;
                }
            }
            & /deep/ .el-input__inner{
                height: 32px;
                line-height: 32px;
                font-size:10px;
            }
        }
    }
    
    .dialog-footer{
        width: 100%;
        text-align: center;
        display: block;
        margin-top: 20px;
    }
    
    .wine-popup-dialog{
        .member-info{
            margin-bottom:20px; 
        }
    }
    .top-info{
        margin: auto;
        margin: 20px;
        background: #fff;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:20px;
        .el-row{
            flex:1;
        }
        .grid-content{
            line-height: 1.4;
            color: black;
            text-align: center;
            border-right: 1px solid rgba(128, 128, 128,.2);
            p{
                font-weight: bold;
                font-size:14px;
            }
        }
        .el-col{
            &:last-child{
                .grid-content{
                    border-right: none;
                }
            }
        }
    }
    .table-list{
        margin: 40px 30px;
        & /deep/ .el-table{
            tr,th,td{
                background: #ffffff;
                color: black;
            }
        }
        .link{
            cursor: pointer;
            text-decoration: underline;
        }
    }
    .pagination-box{
        border-top:1px solid rgba(128, 128, 128, 0.2);
        text-align: center;
        margin-bottom:20px;
        padding-top:30px;
    }
    .add-member-dialog{
        
        .el-form-item{
            width: 48%;
            .el-input{
                width:200px;
            }
        }
    }
}
</style>